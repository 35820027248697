import classes from './Cloud.module.css';

import cloudBannerImg from '../../assets/images/cloudBannerImage.PNG';

import NavBar from '../../component/NavBar/NavBar.jsx';
import ContentTileVideoLeft from '../../component/ContentTileVideoLeft/ContentTileVideoLeft.jsx';
import ContentTileVideoRight from '../../component/ContentTileVideoRight/ContentTileVideoRight.jsx';
import NewsTile from '../../component/NewsTile/NewsTile.jsx';
import Footer from '../../component/Footer/Footer.jsx';

function Cloud() {
  return(
    <div className={classes.container}>
      <NavBar selected={'Cloud'}/>
      <div className={classes.banner}>
        <div className={classes.bannerText}>
          <h1>Automatik</h1>
          <h2 style={{color:'#b98858',fontWeight:'600'}}>Cloud</h2>
          <h2>Manage machine experiences, monitor sales and action changes from your desktop.</h2>
        </div>

        <div className={classes.bannerImage}>
          <img src={cloudBannerImg}/>
        </div>
      </div>
      <div className={classes.highlightBackground}>
        <div className={classes.contentContainer}>
          <ContentTileVideoRight
            title={'Upload templaes and manage designs in minutes.'}
            dash={true}
            paragraph1={'Create emoji like designs in design software, export as SVG and upload to Automatik Cloud. From here, create unlimited number of templats, manage individual touch.'}
            button={true}
            buttonText={'Try now'}
          />

          <ContentTileVideoLeft
            title={'Manage machines remotely, check stock and help in store colleagues with orders'}
            dash={true}
            list={true}
            listElements={['Queue management on any machine.','Review orders and stock', 'History and reporting.', 'Batch production']}
            button={true}
            buttonText={'Try now'}
          />
        </div>
      </div>

      <div className={classes.newsTileBackground}>
        <div className={classes.newsTileTitle}>
          <h2>Our latest News</h2>
        </div>
        <div className={classes.newsTileContainer}>
        <NewsTile
          image={'blogImage1'}
          title={'What are the barriers to selling personalisation profitably & how do you overcome them?'}
          author={'Sam Part'}
        />
        <NewsTile
          image={'blogImage2'}
          title={'What are the best locations for Chocbot?'}
          author={'Sam Part'}
        />
        <NewsTile
          image={'blogImage1'}
          title={'What products are Automakit working on?'}
          author={'Sam Part'}
        />
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Cloud;
