import useState from 'react';

import classes from './MobileNavButton.module.css';

function MobileNavBar(props) {



  function openCloseNav() {

  }

  return (
    <a className={classes.burgerNav} onClick={openCloseNav}>
       <div className={classes.topBar}></div>
       <div className={classes.middleBar}></div>
       <div className={classes.bottomBar}></div>
    </a>
  )
}

export default MobileNavBar;
