import classes from './AboutUs.module.css';

import NavBar from '../../component/NavBar/NavBar.jsx';
import Banner from '../../component/Banner/Banner.jsx';
import ContentTileImageLeft from '../../component/ContentTileImageLeft/ContentTileImageLeft.jsx';
import ContentTileImageRight from '../../component/ContentTileImageRight/ContentTileImageRight.jsx';
import NewsTile from '../../component/NewsTile/NewsTile.jsx';
import Footer from '../../component/Footer/Footer.jsx';

function AboutUs() {
  return(
    <>
    <NavBar borderBottom={true} selected={'AboutUs'}/>
    <Banner
        title={'We provide businesses with the next generation of product experiences'}
        paragraphOne={'Our mission: To make personalisation & customisation convenient for customers and accessible for businesses.'}
        backgroundColor={'#564D5C'}
        textColor={'#fff'}
    />

    <div className={classes.contentTileContainer}>
      <ContentTileImageLeft
        image={'customerExperience'}
        title={'The problem'}
        paragraphOne={'Personalisation and customisation is complex and labour intensive making it hard to deliver.'}
        paragraphTwo={'Customers love it, but the technology has never before been accessible or scalable needing dedicated and trained staff, which in turn makes sales volumes unattainably high for many businesses.'}
      />
      <ContentTileImageRight
        image={'machineProcesses'}
        title={'The Opportunity'}
        paragraphOne={'The demand for personalised products has been growing for the last 10 years, we know this because customers buy online. Couple the proven demand with the convenience that retail offers a customer and you have the largest untapped market opportunity in personalised gifting.'}
      />
      <ContentTileImageLeft
        image={'productIdeas'}
        title={'The Solution'}
        paragraphOne={'Customer led, and automated manufacturing process. Through IOT based experiences coupled with a software platform we can enable remotely manage machines anywhere in the World.'}
        paragraphTwo={'We totally removed the need for a member of staff to make the product, and enabled mass management of 100;s of locations at the same time.'}
      />
    </div>

    <div className={classes.newsTileBackground}>
      <div className={classes.newsTileTitle}>
        <h2>Our latest News</h2>
      </div>
      <div className={classes.newsTileContainer}>
      <NewsTile
        image={'blogImage1'}
        title={'What are the barriers to selling personalisation profitably & how do you overcome them?'}
        author={'Sam Part'}
      />
      <NewsTile
        image={'blogImage2'}
        title={'What are the best locations for Chocbot?'}
        author={'Sam Part'}
      />
      <NewsTile
        image={'blogImage1'}
        title={'What products are Automakit working on?'}
        author={'Sam Part'}
      />
      </div>
    </div>
    <Footer />
    </>
  );
};

export default AboutUs;
