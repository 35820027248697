import { Link } from 'react-router-dom'

import classes from './NavBarButton.module.css';

function NavBarButton(props) {
  return(
    <>
      <Link to={`${props.link}`} className={classes.navButton}>{props.text}</Link>
    </>
  );
};

export default NavBarButton;
