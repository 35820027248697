import classes from './News.module.css';

import NavBar from '../../component/NavBar/NavBar.jsx';
import NewsTile from '../../component/NewsTile/NewsTile.jsx';

function News() {
  return(
    <>
      <NavBar borderBottom={true} selected={'News'}/>
      <div className={classes.newsTileBackground}>
        <div className={classes.newsTileTitle}>
          <h3>Recent</h3>
          <h3 style={{marginLeft:'50px'}}>FAQ's</h3>
        </div>
        <div className={classes.newsTileContainer}>
          <NewsTile
            image={'blogImage1'}
            title={'What are the barriers to selling personalisation profitably & how do you overcome them?'}
            author={'Sam Part'}
          />
          <NewsTile
            image={'blogImage2'}
            title={'What are the best locations for Chocbot?'}
            author={'Sam Part'}
          />
          <NewsTile
            image={'blogImage1'}
            title={'What products are Automakit working on?'}
            author={'Sam Part'}
          />
        </div>
        <div className={classes.newsTileContainer}>
          <NewsTile
            image={'blogImage1'}
            title={'What are the barriers to selling personalisation profitably & how do you overcome them?'}
            author={'Sam Part'}
          />
          <NewsTile
            image={'blogImage2'}
            title={'What are the best locations for Chocbot?'}
            author={'Sam Part'}
          />
          <NewsTile
            image={'blogImage1'}
            title={'What products are Automakit working on?'}
            author={'Sam Part'}
          />
        </div>
      </div>
    </>
  );
};

export default News;
