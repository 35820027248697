import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import '../../assets/css/style.css'
import icon from '../../assets/images/components-icon.svg'
const BG_IMAGES = [
  'images/components.jpg',
  'images/products.jpg',
  'images/services.jpg',
  'images/system-integration.jpg',
]

const TAB_CONTENTS = [
  {
    name: 'About us',
    className: 'components',
    desc: 'Make Personalisation Convenient',
    link:'/AboutUs',
  },
  {
    name: 'Chocbot',
    className: 'products',
    desc: 'Makes Personalised Engraved Chocolate Greetings Cards.',
    link:'/Chocbot',
  },
  {
    name: 'Automakit Cloud',
    className: 'system',
    desc: 'Automakit Cloud',
    link:'/Cloud',
  },
  {
    name: 'Commission us',
    className: 'services',
    desc: 'Manage IOT Machines Anywhere',
    link:'/WorkWithUs',
  },
]

function Home() {
  const [showImageOf, setShowImageOf] = useState({
    prev: -1,
    current: 0,
  })

  const handleMouseOver = (data) => {
    const { prev, current } = data
    if (prev > -1) {
      document.getElementById(`bg_item_${prev}`).style.opacity = '0'
      document.getElementById(`bg_item_${prev}`).style.height = '0'
      document.getElementById(`item_${prev}`).classList.remove('active')
    }
    document.getElementById(`bg_item_${current}`).style.opacity = '1'
    document.getElementById(`bg_item_${current}`).style.height = '100%'
    document.getElementById(`item_${current}`).classList.add('active')
  }

  useEffect(() => {
    handleMouseOver(showImageOf)
  }, [showImageOf])

  const handleMouseEnter = (key) => {
    setShowImageOf({
      prev: showImageOf.current,
      current: key,
    })
  }

  return (
    <main className='main-container'>
      <div className='inner-container'>
        {TAB_CONTENTS.map((item, key) => (
          <div
            className={`section ${item.className}`}
            key={key}
            onMouseEnter={() => handleMouseEnter(key)}
            id={`item_${key}`}
          >
            <Link to={`item.link`}><h1>{item.desc}</h1></Link>
            <div
              id={`bg_item_${key}`}
              className={`image-container`}
              style={{ backgroundImage: `url('${BG_IMAGES[key]}')` }}
            />
            <div className='bottom-section'>
              <img src={icon} alt='' />
              <Link to={`${item.link}`}><span>{item.name}</span></Link>
            </div>
          </div>
        ))}
      </div>
    </main>
  )
}

export default Home
