import classes from './ContactUs.module.css';
import contactUsImg from '../../assets/images/contactUsImage.PNG';

import NavBar from '../../component/NavBar/NavBar.jsx';

function ContactUs() {
  return(
    <>
      <NavBar borderBottom={true} />
      <div className={classes.container}>
        <div className={classes.formContainer}>
          <h2>Drop us a line</h2>
          <input placeholder={'Full Name'} type={'text'}/>
          <input placeholder={'Company'} type={'text'}/>
          <input placeholder={'Email Address'} type={'text'}/>
          <textarea placeholder={'Message'}/>
          <button>Submit</button>
        </div>

        <div className={classes.formImageContainer}>
          <img src={contactUsImg} />
        </div>
      </div>
    </>
  );
};

export default ContactUs
