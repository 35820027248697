import classes from './Footer.module.css';

function Footer() {
  return(
    <div className={classes.container}>
      <h2>Automakit</h2><span>Copyright 2022</span>
    </div>
  );
};

export default Footer;
