import { Link } from 'react-router-dom'
import classes from './NavBarElement.module.css';

function NavBarElement(props) {
  return(
    <>
    {props.selected == true
        ? <Link to={`${props.link}`} className={classes.selected}>{props.text}</Link>
        : <Link to={`${props.link}`} className={classes.unselected}>{props.text}</Link>
    }
    </>
  );
};

export default NavBarElement;
