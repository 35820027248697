import React from "react"
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';

import "./App.css"
import Header from "./component/Header/header"
import Home from "./component/Home"

import AboutUs from './routes/AboutUs/AboutUs.jsx';
import WorkWithUs from './routes/WorkWithUs/WorkWithUs.jsx';
import Chocbot from './routes/Chocbot/Chocbot.jsx';
import Cloud from './routes/Cloud/Cloud.jsx';
import News from './routes/News/News.jsx';
import ContactUs from './routes/ContactUs/ContactUs.jsx';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={'/'} exact element={<Home/>} />
          <Route path={'/AboutUs'} exact element={<AboutUs/>} />
          <Route path={'/WorkWithUs'} exact element={<WorkWithUs/>} />
          <Route path={'/Chocbot'} exact element={<Chocbot/>} />
          <Route path={'/Cloud'} exact element={<Cloud/>} />
          <Route path={'/News'} exact element={<News/>} />
          <Route path={'/ContactUs'} exact element={<ContactUs/>} />
        </Routes>
      </BrowserRouter>
    </>

  )
}

export default App

// this is one  animation
// now I'm showing you the second animation that I implemneted
