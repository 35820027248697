import classes from './WorkWithUs.module.css';

import NavBar from '../../component/NavBar/NavBar.jsx';
import Banner from '../../component/Banner/Banner.jsx';
import ContentTileImageLeft from '../../component/ContentTileImageLeft/ContentTileImageLeft.jsx';
import ContentTileImageRight from '../../component/ContentTileImageRight/ContentTileImageRight.jsx';
import NewsTile from '../../component/NewsTile/NewsTile.jsx';
import Footer from '../../component/Footer/Footer.jsx';

function WorkWithUs() {
  return(
    <>
      <NavBar borderBottom={true} selected={'WorkWithUs'}/>
      <Banner
          title={'Commision Us'}
          paragraphOne={'Do you have a product that is enhanced by personalisation? Do you currently sell online but want to exploit the retail opportunity? Do you want to automate your online fulfilment?'}
          paragraphTwo={'We design and build machine experiences around all kinds of products and integrate to our cloud platform for simple, people free experiences.'}
          backgroundColor={'#F9ECEA'}
      />

      <div className={classes.contentTileContainer}>
        <ContentTileImageLeft
          image={'customerExperience'}
          title={'Customer Experiences'}
          paragraphOne={'With our propreity software service we can connect touch screens or websites to any kid of Automakit machine.'}
          paragraphTwo={'Design a user experience around personalising your product Online or in store. We build and integrate to Automakit cloud to benefit from live reporting management and automation.'}
        />
        <ContentTileImageRight
          image={'machineProcesses'}
          title={'Machine Processes'}
          paragraphOne={'We can automate all kinds of machine processes to eliminate the need for members of staff on fulfilment.'}
          paragraphTwo={'Print, Plotting, CNC engraving, Laser engraving:'}
          paragraphThree={'And these processes can be used with all kinds of materials. From food stuffs, to metal & woods, plastics, papers, fabrics and more.'}
        />
        <ContentTileImageLeft
          image={'productIdeas'}
          title={'Product Ideas'}
          paragraphOne={'Jewellery (Rings, necklesses, bracelets, ear rings etc)'}
        />
      </div>

      <div className={classes.newsTileBackground}>
        <div className={classes.newsTileTitle}>
          <h2>Our latest News</h2>
        </div>
        <div className={classes.newsTileContainer}>
        <NewsTile
          image={'blogImage1'}
          title={'What are the barriers to selling personalisation profitably & how do you overcome them?'}
          author={'Sam Part'}
        />
        <NewsTile
          image={'blogImage2'}
          title={'What are the best locations for Chocbot?'}
          author={'Sam Part'}
        />
        <NewsTile
          image={'blogImage1'}
          title={'What products are Automakit working on?'}
          author={'Sam Part'}
        />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WorkWithUs;
