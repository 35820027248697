import classes from './NewsTile.module.css';

import blogImage1Img from '../../assets/images/blogImage1.PNG';
import blogImage2Img from '../../assets/images/blogImage2.PNG';

function NewsTile(props) {
  const newsImage = {
    blogImage1: blogImage1Img,
    blogImage2: blogImage2Img
  };

  return(
    <div className={classes.container}>
      <img src={newsImage[props.image]} />
      <div className={classes.textContainer}>
        <div className={classes.titleText}>
          <h3>{props.title}</h3>
        </div>
        <span>_</span>
        <h4>Author: {props.author}</h4>
      </div>
    </div>
  )
}

export default NewsTile;
