import classes from './ContentTileImageRight.module.css';

import customerExperienceImg from '../../assets/images/customerExperience.PNG';
import machineProcessesImg from '../../assets/images/machineProcesses.PNG';
import productIdeasImg from '../../assets/images/productIdeas.PNG';

function ContentTileImageLeft(props) {
  const tileImage = {
    customerExperience: customerExperienceImg,
    machineProcesses: machineProcessesImg,
    productIdeas: productIdeasImg
  };

  return(
    <div className={classes.container}>
    <div className={classes.mobileImageContainer}>
      <img src={tileImage[props.image]} />
    </div>
      <div className={classes.textContainer}>
        <h3>{props.title}</h3>
        <p>{props.paragraphOne}</p>
        <p>{props.paragraphTwo}</p>
        <p>{props.paragraphThree}</p>
      </div>

      <div className={classes.imageContainer}>
        <img src={tileImage[props.image]} />
      </div>
    </div>
  );
};

export default ContentTileImageLeft;
