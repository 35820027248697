import classes from './ContentTileVideoLeft.module.css';

function ContentTileVideoLeft(props) {
  return(
    <div className={classes.container}>
      <div className={classes.videoContainer}>
      </div>
      <div className={classes.textBox}>
        <h3>{props.title}</h3>

        <h4>{props.paragraph1}</h4>
        <h4>{props.paragraph2}</h4>
        {props.list == true
          ? props.listElements.map((list) => {
            return(
              <li className={classes.listElement}>{list}</li>
            )
          })
          : ''
        }
        {props.button == true
          ?<button className={classes.experienceButton}>Experience Now</button>
          :''
        }
      </div>
    </div>
  );
};

export default ContentTileVideoLeft;
