import classes from './Chocbot.module.css';

import NavBar from '../../component/NavBar/NavBar.jsx';
import ContentTileVideoLeft from '../../component/ContentTileVideoLeft/ContentTileVideoLeft.jsx';
import ContentTileVideoRight from '../../component/ContentTileVideoRight/ContentTileVideoRight.jsx';
import FAQTile from './components/FAQTile.jsx';
import Footer from '../../component/Footer/Footer.jsx';

function Chocbot() {
  return(
    <>
    <div className={classes.container}>
      <div className={classes.banner}>
        <NavBar selected={'Chocbot'}/>
          <div className={classes.bannerText}>
            <h1>Chocbot</h1>
            <h2>A customer led, automated vending machine experience for personalised and customised products.</h2>
            <button className={classes.roundedButton}>Find out how it works</button>
          </div>
          <div className={classes.mobileBannerText}>
            <h1 className={classes.mobileBannerTitle}>Chocbot</h1>
          </div>
      </div>
      <div className={classes.contentContainer}>
        <h1 className={classes.contentTitle}>Chocbot</h1>
        <div className={classes.chocbotOpeningParagraph}>The first in a suite of machines designed to lower the barriers around personalisation by enabling in store on demand manufacturing with cloud based management.</div>
        <ContentTileVideoRight
          title={'Customer led personalisation through our touch screen experience'}
          dash={true}
          paragraph1={'Customers choose from hundred of templated designs before adding their own'}
          button={true}
          buttonText={'Experience Now'}
        />
        <ContentTileVideoLeft
          title={'Instant on demand manufacturing'}
          dash={true}
          paragraph1={'Customers watch as our machine engraves and packages the product they designed in just minutes.'}
          list={true}
          listElements={['Dispensed in between 2-5 minutes.', '50 capacity.', 'Unlimited designs.', 'Storage features for click & collect or pick up later options']}
        />
        <ContentTileVideoRight
          image={'engravedChocolateGreetingsCard'}
          imageRender={true}
          paragraph1={'What better way to say what you have to say than in chocolate?'}
        />
        <ContentTileVideoRight
          title={'Off the shelf cards'}

          dash={true}
          paragraph1={'Display up to as many as 60 different cards for any occasion ready to buy immediately at the'}
          paragraph2={'Easily curated for seasonal events. Every product is manufactureing in sotre viahe Automakit Cloud platform. When made, local staff members stock.'}
        />
        <ContentTileVideoRight
          title={'Designs for every occasion'}
          paragraph1={'We have created 100s of the existing personalised and non personalised templates for all demographics at all times of year. Add unlimited templates in minutes on the Automakit cloud.'}
        />
      </div>
      <div className={classes.highlightBackground}>
        <div className={classes.highlightBackgroundContent}>
        <ContentTileVideoLeft
          title={'Click & Collect'}
          dash={true}
          list={false}
          paragraph1={'Customers watch as our machine engraves and packages the product they designed in just minutes.'}
        />
        <ContentTileVideoRight
          title={'Technology designed to be used'}
          image={'engravedChocolateGreetingsCard'}
          paragraph1={'The machine can hold 50-100 chocolate bars and up to 50 packaging units.'}
          paragraph2={'Easily loaded, cleaned and maintained this on demand machine is retail theatre at the click.'}
        />
        </div>
      </div>

      <div className={classes.contentContainer}>
        <h1>FAQ's</h1>
        <FAQTile title={'Machine requirements'}/>
        <FAQTile title={'Production details'}/>
        <FAQTile title={'Chocolate & packaging details'}/>
        <FAQTile title={'What is the business model?'}/>
      </div>

      <Footer />
    </div>
    </>
  );
};

export default Chocbot;
