import classes from './Banner.module.css';

import customerExperienceImg from '../../assets/images/customerExperience.PNG';
import playButtonImg from '../../assets/images/playButton.svg';

function Banner(props){
  return(
    <div className={classes.container} style={{ backgroundImage: `url(${customerExperienceImg})` }}>
      <div className={classes.tint} style={{ backgroundColor:`${props.backgroundColor}`,color:`${props.textColor}`}}>
        <h2>{props.title}</h2>
        <h3>{props.paragraphOne}</h3>
        <h3>{props.paragraphTwo}</h3>
        <img style={{ fill:`${props.textColor}`}}src={playButtonImg} />
      </div>
    </div>
  );
};

export default Banner;
