import { useState } from 'react';
import { Link } from 'react-router-dom'

import NavBarElement from './components/NavBarElement.jsx';
import NavBarButton from './components/NavBarButton.jsx';
import MobileNavBar from './components/MobileNavButton.jsx'

import classes from './NavBar.module.css';

function NavBar(props) {

  return(
   <div className={classes.container} style={{borderBottom: props.borderBottom == true
        ? 'solid 1px black'
        : ''
      }}>
    <div className={classes.logo}>
      <Link to={'/'}><h1>Automakit</h1></Link>
    </div>

    <div className={classes.navigation}>
      {props.selected == 'AboutUs'
      ? <NavBarElement link={'/AboutUs'} text={'About Us'} selected={true}/>
      : <NavBarElement link={'/AboutUs'} text={'About Us'} />
      }

      {props.selected == 'WorkWithUs'
      ? <NavBarElement link={'/WorkWithUs'} text={'Work with us'} selected={true}/>
      : <NavBarElement link={'/WorkWithUs'} text={'Work with us'} />
      }

      {props.selected == 'Chocbot'
      ? <NavBarElement link={'/Chocbot'} text={'Chocbot'} selected={true}/>
      : <NavBarElement link={'/Chocbot'} text={'Chocbot'} />
      }

      {props.selected == 'Cloud'
      ? <NavBarElement link={'/Cloud'} text={'Cloud'} selected={true}/>
      : <NavBarElement link={'/Cloud'} text={'Cloud'} />
      }

      {props.selected == 'News'
      ? <NavBarElement link={'/News'} text={'News'} selected={true}/>
      : <NavBarElement link={'/News'} text={'News'} />
      }


      <NavBarButton link={'/ContactUs'} text={'Contact us'} />
      <NavBarButton link={'/CloudLogin'} text={'Cloud Login'} />
   </div>

   <div className={classes.mobileNavigation} >
      <MobileNavBar onSelect={() => props.onSelect()}/>
    </div>
  </div>
  )
}

export default NavBar;
