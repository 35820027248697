import engravedChocolateGreetingsCardImg from '../../assets/images/engravedChocolateGreetingsCards.PNG';

import classes from './ContentTileVideoRight.module.css';

function ContentTileVideoLeft(props) {

  const tileImage = {
    engravedChocolateGreetingsCard: engravedChocolateGreetingsCardImg
  }

  return(
    <div className={classes.container}>
      <div className={classes.textBox}>
        {props.imageRender == true
          ? <img src={tileImage[props.image]}/>
          : ''
        }
        <h3>{props.title}</h3>

        <h4>{props.paragraph1}</h4>
        <h4>{props.paragraph2}</h4>
        {props.button == true
          ?<button className={classes.experienceButton}>{props.buttonText}</button>
          :''
        }
      </div>
      <div className={classes.videoContainer}>
      </div>
    </div>
  );
};

export default ContentTileVideoLeft;
