import classes from './FAQTile.module.css';

function FAQTile(props) {
  return(
    <div className={classes.container}>
      <h2>{props.title}</h2>
      <div></div>
    </div>
  );
};

export default FAQTile;
